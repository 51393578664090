import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from "../components/Button";

interface TextData {
  header: {
    title: string;
    subtitle: string;
  };
  productos: Array<{
    title: string;
    description: string;
    imageUrl: string;
  }>;
  servicios: {
    title: string;
    items: Array<{
      title: string;
      description: string;
      icon: string; // Icono SVG en formato string
    }>;
  };
  proyectos: {
    title: string;
    items: Array<{
      imageUrl: string;
    }>;
  };
  contacto: {
    title: string;
  };
}

const HomePage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telefono: '',
    description: '',
  });
  const [isSending, setIsSending] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [textData, setTextData] = useState<TextData | null>(null);

  // Validación del formulario
  useEffect(() => {
    const { name, email, telefono, description } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const telefonoRegex = /^\+569\d{8}$/;
    const isValid =
      name.trim() !== '' &&
      emailRegex.test(email) &&
      telefonoRegex.test(telefono.replaceAll(' ', '')) &&
      description.trim() !== '';
    setIsFormValid(isValid);
  }, [formData]);

  // Manejo de scroll para mostrar el botón de subir
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!isFormValid || isSending) return;

    setIsSending(true);

    try {
      const response = await axios.post(
        'https://southamerica-east1-cg-elevadores.cloudfunctions.net/sendEmailFunction',
        formData,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );

      if (response.status === 200) {
        alert('Correo enviado con éxito.');
        setFormData({ name: '', email: '', telefono: '', description: '' });
      } else if (response.status === 429) {
        alert(
          'Ya has enviado un correo hoy. Por favor, inténtalo de nuevo mañana.'
        );
      } else {
        alert('Error al enviar el correo.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error al enviar el correo.');
    } finally {
      setIsSending(false);
    }
  };

  // Obtener los textos desde la Cloud Function
  useEffect(() => {
    const fetchTextData = async () => {
      try {
        const response = await axios.get(
          'https://southamerica-east1-cg-elevadores.cloudfunctions.net/getLandingPageTexts'
        );
        if (response.status === 200) {
          console.log(response)
          setTextData(response.data);
        } else {
          console.error('No se pudieron cargar los textos desde Firebase.');
        }
      } catch (error) {
        console.error('Error al obtener los textos:', error);
      }
    };

    fetchTextData();
  }, []);

  if (!textData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="px-4 sm:px-10 md:px-20 lg:px-40 flex flex-1 justify-center py-5">
      <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
        {/* Inicio */}
        <section
          id="inicio"
          className="flex min-h-[320px] md:min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat items-start justify-end px-4 pb-10"
          style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url('https://cdn.usegalileo.ai/sdxl10/5108feab-c3f7-4495-937f-38ab20457fd9.png')" }}
        >
          <div className="flex flex-col gap-2 text-left">
            <h1 className="text-white text-2xl sm:text-3xl md:text-4xl font-black leading-tight tracking-[-0.033em]">
              {textData.header.title}
            </h1>
            <h2 className="text-white text-sm sm:text-base md:text-lg font-normal leading-normal">
              {textData.header.subtitle}
            </h2>
          </div>
        </section>

        {/* Productos */}
        <section id="productos" className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 p-4">
          {textData.productos.map((producto, index) => (
            <div key={index} className="flex flex-col gap-3 pb-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url(${producto.imageUrl})` }}></div>
              <div>
                <p className="text-[#0e141b] text-base font-medium leading-normal">{producto.title}</p>
                <p className="text-[#4e7397] text-sm font-normal leading-normal">{producto.description}</p>
              </div>
            </div>
          ))}
        </section>

        {/* Servicios */}
        <section id="servicios">
          <h2 className="text-[#0e141b] text-lg sm:text-xl md:text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
            {textData.servicios.title}
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 p-4">
            {textData.servicios.items.map((servicio, index) => (
              <div key={index} className="flex flex-1 gap-3 rounded-lg border border-[#d0dbe7] bg-slate-50 p-4 flex-col">
                <div className="text-[#0e141b]" dangerouslySetInnerHTML={{ __html: servicio.icon }} />
                <div className="flex flex-col gap-1">
                  <h2 className="text-[#0e141b] text-base font-bold leading-tight">{servicio.title}</h2>
                  <p className="text-[#4e7397] text-sm font-normal leading-normal">{servicio.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Proyectos */}
        <section id="proyectos">
          <h2 className="text-[#0e141b] text-lg sm:text-xl md:text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
            {textData.proyectos.title}
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 p-4">
            {textData.proyectos.items.map((proyecto, index) => (
              <div key={index} className="flex flex-col gap-3">
                <div
                  className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                  style={{ backgroundImage: `url(${proyecto.imageUrl})` }}
                ></div>
              </div>
            ))}
          </div>
        </section>

        {/* Contacto */}
        <section id="contacto" className="layout-content-container flex flex-col max-w-[960px] flex-1">
          <div className="flex justify-center">
            <div className="w-full max-w-[480px] bg-white p-6 rounded-xl shadow-md">
              <h2 className="text-[#0e141b] text-xl sm:text-2xl font-bold mb-6 text-left">
                {textData.contacto.title}
              </h2>
              <form className="space-y-4">
                <div className="flex flex-col sm:flex-row items-center">
                  <label
                    htmlFor="name"
                    className="block text-[#0e141b] text-base font-medium mb-2 sm:mb-0 sm:mr-4 sm:w-1/3 text-left"
                  >
                    Nombre
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Tu nombre"
                    className="w-full sm:w-2/3 h-12 px-4 rounded-xl border border-gray-300 focus:outline-none focus:border-[#1466b8] transition-colors"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-center">
                  <label
                    htmlFor="email"
                    className="block text-[#0e141b] text-base font-medium mb-2 sm:mb-0 sm:mr-4 sm:w-1/3 text-left"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="tuemail@ejemplo.com"
                    className="w-full sm:w-2/3 h-12 px-4 rounded-xl border border-gray-300 focus:outline-none focus:border-[#1466b8] transition-colors"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-center">
                  <label
                    htmlFor="telefono"
                    className="block text-[#0e141b] text-base font-medium mb-2 sm:mb-0 sm:mr-4 sm:w-1/3 text-left"
                  >
                    Teléfono
                  </label>
                  <input
                    id="telefono"
                    name="telefono"
                    type="text"
                    value={formData.telefono}
                    onChange={handleChange}
                    placeholder="+56 9 12345678"
                    className="w-full sm:w-2/3 h-12 px-4 rounded-xl border border-gray-300 focus:outline-none focus:border-[#1466b8] transition-colors"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-center">
                  <label
                    htmlFor="description"
                    className="block text-[#0e141b] text-base font-medium mb-2 sm:mb-0 sm:mr-4 sm:w-1/3 text-left"
                  >
                    Descripción
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Escribe tu mensaje aquí..."
                    rows={5}
                    className="w-full sm:w-2/3 px-4 py-3 rounded-xl border border-gray-300 focus:outline-none focus:border-[#1466b8] transition-colors resize-none"
                  />
                </div>
                <div>
                  <Button
                    label={isSending ? 'Enviando...' : 'Enviar'}
                    onClick={handleSubmit}
                    disabled={!isFormValid || isSending}
                  />
                </div>
              </form>
            </div>
          </div>
        </section>

        {showScrollToTop && (
          <button
            onClick={handleScrollToTop}
            className="fixed bottom-5 right-5 p-3 bg-gray-300 text-gray-700 rounded-full shadow-lg hover:bg-blue-300 transition-opacity duration-300"
            aria-label="Subir al inicio"
          >
            ↑
          </button>
        )}
      </div>
    </div>
  );
};

export default HomePage;
