import React from 'react';

const Header: React.FC = () => (
  <header className="flex flex-col sm:flex-row items-center justify-between whitespace-nowrap border-b border-solid border-b-[#e7edf3] px-5 sm:px-10 py-3">
    <div className="flex items-center gap-4 text-[#0e141b] mb-3 sm:mb-0">
      <div className="w-80 h-80 sm:w-80 sm:h-12">
        <img src="/logo-horizontal-v2.png" alt="CG Elevadores SPA" className="w-full h-full object-contain"/>
      </div>
    </div>
    <div className="flex flex-wrap justify-center sm:flex-1 sm:justify-end gap-4 sm:gap-8">
      <a className="text-[#0e141b] text-sm sm:text-base font-medium leading-normal" href="#inicio">Inicio</a>
      <a className="text-[#0e141b] text-sm sm:text-base font-medium leading-normal" href="#productos">Productos</a>
      <a className="text-[#0e141b] text-sm sm:text-base font-medium leading-normal" href="#servicios">Servicios</a>
      <a className="text-[#0e141b] text-sm sm:text-base font-medium leading-normal" href="#proyectos">Proyectos</a>
      <a className="text-[#0e141b] text-sm sm:text-base font-medium leading-normal" href="#contacto">Contacto</a>
    </div>
  </header>
);

export default Header;
