import React from 'react';
import './App.css';
import MainLayout from "./layouts/MainLayout";
import HomePage from "./pages/HomePage";

const App:  React.FC = () => {
  return (
    <div className="App">
      <MainLayout>
        <HomePage />
      </MainLayout>
    </div>
  );
}

export default App;
