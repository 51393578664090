import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex flex-col min-h-screen">
    <Header />
      <main className="flex flex-1">{children}</main>
    <Footer />
  </div>
);

export default MainLayout;
