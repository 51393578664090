import React from 'react';

interface ButtonProps {
  label?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  icon?: JSX.Element;
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, type = 'button', icon, className = '', disabled = false }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`flex items-center justify-center w-full h-12 px-5 rounded-xl text-base font-bold leading-normal tracking-[0.015em] transition-colors duration-300 
        ${disabled
        ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
        : 'bg-[#1466b8] text-white hover:bg-[#125a9e]'} 
        ${className}`}
    >
      {icon && <div className="mr-2">{icon}</div>}
      {label && <span className="truncate">{label}</span>}
    </button>
  );
};

export default Button;